  .footer-box {
  width: 100%;
  margin-top: auto;
  padding-top: 2em;
}

footer {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  bottom: 0px;
}

.footer-container {
  display: flex;
  width: 70%;
  justify-content: space-between;
  flex-direction: column;
  /* flex-wrap: wrap; */
}

.footer-col {
  flex: 1;
  margin-right: 20px;
  min-width: 200px;
}

.footer-copyright {
  flex: 1;
  text-align: center;
  padding-top: 1em;
}

footer h5 {
  color: #333;
  font-size: 18px;
  margin-bottom: 10px;
}

footer p, footer li {
  color: #666;
  font-size: 14px;
  line-height: 1.6;
}

.footer-page-link {
  color: #0077c9;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}


@media (min-width: 1200px) {
  .footer-container {
    flex-direction: row;
  }

}