.recruiters-checkbox-box {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: left;
}

.recruiters-checkbox {
    accent-color: #228ad0;
    margin-right: 0.5em;
}

.email-input-label {
    font-size: 1.2em;
    font-weight: 300;
    margin-bottom: 0.5em;
    margin-right: 0.5em;
    margin-left: 0.2em
}

#email-input {
    width: 70%;
    accent-color: #228ad0;
}