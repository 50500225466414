.job-ad-free-text-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}


.job-ad-free-text-box {
  display: flex;
  align-items: left;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.job-ad-free-text-box textarea {
  margin-bottom: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 3px;
  accent-color: #228ad0;
  height: 15em;
  overflow-y: scroll;
  overflow-x: wrap;
  align-items: left;
  resize: vertical;
  padding: 1em 10px;;
  font-family: sans-serif;
  font-weight: 400;
  color: #444
}

h3 {
  font-weight: 300;
  margin-bottom: 0.5em;
}
