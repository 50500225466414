.generator-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    max-width: 70em;
    /* min-width: 15em; */
}

.generator-heading {
  font-size: 2em;
  margin-top: -0.5em;
  font-weight: lighter;
}

form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
  }

.job-ad-free-text-box textarea {
    margin-bottom: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 3px;
    accent-color: #228ad0;
    height: 15em;
    overflow-y: scroll;
    overflow-x: wrap;
    align-items: left;
    resize: vertical;
    padding: 1em 10px;;
    font-family: sans-serif;
    font-weight: 400;
    color: #444
  }

form label {
  
  font-size: 14px;
  margin-bottom: 10px;
  color: #333;
}

form input {
  /* width: 100%; */
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-family: sans-serif;
  font-weight: 400;
  color: #444
}

.generator-submit-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #0077c9;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 3px;
  transition: background-color 0.2s ease-in-out;
}

.generator-submit-button-disabled {
  background-color: #ccc;
  color: white;
}

.generator-submit-button-enabled:hover {
  background-color: #006ab3;
  cursor: pointer;
}

.cover-letter {
  width: 100%;
  padding: 20px;
  background-color: #f5f5f5;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.letter-like-format {
  font-size: 18px;
  font-family: Times New Roman, Times, serif;
  padding: 20px;
  line-height: 1.6;
  color: #333;
  white-space: pre-wrap;
}

.cv-upload-box {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.cv-upload-box h3 {
  font-weight: 300;
  margin-bottom: 0.5em;
}

.url-input-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  accent-color: #228ad0;
}

.url-input-form h3 {
  font-weight: 300;
  margin-bottom: 0.5em;
}

.checkbox-wrapper {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
}

.instapply-logo-text {
  color: #333;
  font-size: 1.4em;
}


.spin-loader {
  margin-top: 5em;
  margin-bottom: 5em;
}

.scraping-failed-warning-box {
  width: 100%;
  border: 3px solid rgb(231, 170, 170);
  background-color: rgb(246, 234, 234);
  border-radius: 15px;
  color: #333;
}

.scraping-failed-warning {
  /* width: 100%; */
  padding: 0em 1em;
  color: #444;
  font-weight: 300;
  font-size: small;
}

.generator-h2 {
  font-weight: 300;
  margin-bottom: 0.5em;
  margin-right: auto;
}


