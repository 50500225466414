.pdf-uploader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px dashed #ccc;
    padding: 20px;
    border-radius: 5px;
  }
  
  .pdf-uploader input[type="file"] {
    display: none;
  }
  
  .pdf-uploader .custom-file-upload {
    display: inline-block;
    padding: 10px 15px;
    cursor: pointer;
    background-color: #228ad0;
    color: white;
    border-radius: 5px;
    border: none;
    margin-bottom: 10px;
  }
  
  .pdf-uploader .custom-file-upload:hover {
    background-color: #57a5da;
  }
  
  .pdf-uploader p.added-file {
      color: #228ad0;
      font-style: italic;
      margin-top: 10px;
  }
  

  .upload-button {
    display: inline-block;
    padding: 10px 15px;
    cursor: pointer;
    background-color: #228ad0;
    color: white;
    border-radius: 5px;
    border: none;
    margin-bottom: 10px;
  }

  .upload-button:hover {
    background-color: #57a5da;
  }


  