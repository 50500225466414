header {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0px;
  background-color: #f5f5f5;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 2em;
}

header img {
  width: 10px;
  height: 10px;
}

.header-logo {
  max-width: 10em;
}

.header-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  flex-direction: column;
}

.header-always-top {
  justify-content: space-between;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 4;
}
  
header nav {
  display: flex;
}
  
.nav-page-button {
  margin-left: 20px;
  background-color: transparent;
  border: none;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
  padding: 1em 0em;
}

.nav-page-button:hover {
  color: #0077c9;
}

.instapply-logo-text:hover {
  color: #0077c9;
  transition: color 0.1s ease-in-out;
}


.navBar button {
  /* position: fixed; */
  right: 40px;
  top: 40px;
  z-index: 10;
  cursor: pointer;
}

.header-hamburger-button {
  font-size: 2em;
  right: 40px;
  top: 40px;
  z-index: 10;
  cursor: pointer;
}

.menuNav {
  /* overflow-y: scroll; */
  list-style: none;
  /* position: fixed; */
  top: 0;
  /* background: darkcyan; */
  right: 0;
  bottom: 0;
  /* height: 100vh; */
  visibility: hidden;
  height: 0;
  /* overflow: hidden; */
  z-index: 9;
  display: flex;
  flex-direction: row;
  align-items: center;
  
  overflow: hidden;
}

.menuNav.showMenu {
  visibility: visible;
  height: auto;
  width: 100%;
  flex-direction: column;
}

.header-hamburger-button {
  display: visible;
}

@media (min-width: 1300px) {

  .navBar {
    flex-direction: row;
  }

  .menuNav, .menuNav.showMenu {
    visibility: visible;
    flex-direction: row;
    justify-content: right;
    height: auto;
    width: 100%;
  }

  .header-hamburger-button {
    visibility: hidden;
  }
}


