.gdpr-checkbox-wrapper {
    display: flex;
    align-items: center;
    margin-top: 3em;
    margin-bottom: -1em;
    flex-direction: row;
    justify-content: left;
}

.consent-checkbox {
    accent-color: #228ad0;
    margin-right: 0.5em;
}


.privacy-poliy-link {
    color: #228ad0;
    font-weight: 600;
    text-decoration: none;
}
  