.App {
  font-family: 'Open Sans', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

main {
  width: 80%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

a {
  color: inherit;
  text-decoration: inherit; /* no underline */
}


.line-break-text {
  white-space: pre-wrap;
}
