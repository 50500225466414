.card {
    display: flex;
    flex-direction: column;
    background-color: #F5F5F5;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #BBBBBB;
    margin-bottom: 20px;
    transition: 0.3s ease-in-out;
  }
  
  .card:hover {
    background-color: #E0E0E0;
    transform: translateY(-5px);
  }
  
  .card-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .HUB-title {
    font-size: 34px;
    font-weight: bold;
    margin-bottom: 16px;
    /* text-transform: uppercase; */
    letter-spacing: 2px;
    color: #333;
    text-align: center;
  }
  
  .HUB-text {
    font-size: 16px;
    line-height: 1.4;
    margin-bottom: 10px;
    text-align: justify;
    color: #333;
  }
  
  .card-text {
    font-size: 16px;
    line-height: 1.4;
    margin-bottom: 10px;
    text-align: justify;
    color: #333;
  }
  
  .card-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    margin-bottom: 10px;
    border-radius: 10px;
  }